import React from "react";
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import Layout from "../components/layout";
import DOD from '../components/dods';
import Vimeo from '@u-wave/react-vimeo';

var pageTitle = 'Campaign Congress Holiday Update';

var pageDesc = '“It Takes a Titan: The Campaign for Cal State Fullerton,” is the university’s first-ever comprehensive philanthropic campaign. The five-year, $250 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community. It is an opportunity for the entire Titan family – faculty, staff, alumni and community members to be the difference in elevating the potential of our remarkable students.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="about" />
      <meta itemprop="name" content={pageTitle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDesc} />
      <meta itemprop="description" content={pageDesc} />
      <meta name="twitter:description" content={pageDesc} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section className="content">
      <article className="fixed">
        <h1 className="center">{pageTitle}</h1>
        <Vimeo
          video="652605066"
          responsive
          showTitle="false"
          showByline="false"
          showPortrait="false"
        />
        <p>Thank you to all the members of the It Takes a Titan Campaign Congress. The Campaign Congress includes Titans across campus who serve as campaign ambassadors and liaisons between their colleges or units and University Advancement <Link to="/board">campaign leadership</Link>.</p>
      </article>
    </section>
    <DOD />
  </Layout>
  </React.Fragment>
);
